import { Constants } from "../constants";

export const checkSignUpForm = (
  {
    email,
    password,
    passwordConfirmation,
  }:{
    email: string
    password: string
    passwordConfirmation: string
  }): string => {
  if (!Constants.regex.email.test(email)) {
    return 'メールアドレスの形式が不正です。';
  }
  if (!Constants.regex.password.test(password)) {
    return 'パスワードの形式が不正です。パスワードは大文字と小文字を含む最低8文字以上で入力してください。';
  }
  if (password != passwordConfirmation) {
    return 'パスワードが一致しません。';
  }
  return '';
};
