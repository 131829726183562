import { useState } from 'react';
import { firebaseAuth } from '../firebaseAuth';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Grid } from '@mui/material';
import i18n from '../i18n';
import { redirect } from 'react-router-dom';
import { Constants } from '../constants';
import I18n from '../i18n';
import { Simulate } from 'react-dom/test-utils';
import Notification from '../components/Notification';
import styles from '../styles/Common.module.css';

export default function SignIn() {
  const [signInForm, setSignInForm] = useState({
    email: {
      value: '',
      error: '',
    },
    password: {
      value: '',
      error: '',
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  const [openNotification, setOpenNotification] = useState(false);
  const [httpRequestErrorMessage, setHttpRequestErrorMessage] = useState('');

  const isValidSignInForm = (form: typeof signInForm): boolean => {
    let isValid = true;
    if (!Constants.regex.email.test(form.email.value)) {
      form = structuredClone(form);
      form.email.error = I18n.t('error.invalid_email');
      setSignInForm(form);
      isValid = false;
    }

    return isValid;
  };

  const signIn = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValidSignInForm(signInForm)) {
      return;
    }
    signInWithEmailAndPassword(
      firebaseAuth,
      signInForm.email.value,
      signInForm.password.value
    )
      .then(() => {})
      .catch((error) => {
        let error_message;
        switch (error.code) {
          case 'auth/user-not-found':
            error_message = I18n.t('error.user_not_found');
            break;
          case 'auth/wrong-password':
            error_message = I18n.t('error.wrong_password');
            break;
          case 'auth/too-many-requests':
            error_message = I18n.t('error.too_many_requests');
            break;
          default:
            error_message = I18n.t('error.contact_support');
            break;
        }
        setHttpRequestErrorMessage(error_message);
        setOpenNotification(true);
      });
  };
  return (
    <Grid container>
      {httpRequestErrorMessage && (
        <Notification
          alertMessage={httpRequestErrorMessage}
          openNotification={openNotification}
          setOpenNotification={setOpenNotification}
        />
      )}
      <Grid item md={5}>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 hidden md:block">
          <img src="/images/signin.png"></img>
        </div>
      </Grid>
      <Grid item md={7} className="w-full">
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 md:py-12 lg:px-8">
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={signIn}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {i18n.t('noun.email')}
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={signInForm.email.value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSignInForm(
                        Object.assign({}, signInForm, {
                          email: { value: e.target.value, error: '' },
                        })
                      );
                    }}
                    required
                    className={styles.formInput}
                  />
                  <p className="text-red-500 text-xs">
                    {signInForm.email.error}
                  </p>
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {i18n.t('noun.password')}
                </label>
                <div className="mt-2 flex">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    required
                    value={signInForm.password.value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSignInForm(
                        Object.assign({}, signInForm, {
                          password: { value: e.target.value, error: '' },
                        })
                      );
                    }}
                    className={styles.formInput}
                  />
                  <button
                    className="ml-1"
                    type="button"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    {showPassword && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                        />
                      </svg>
                    )}
                    {!showPassword && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    )}
                  </button>
                  <p className="text-red-500 text-xs">
                    {signInForm.password.error}
                  </p>
                </div>
                <div className="text-sm">
                  <a
                    href="/reset-password"
                    className="font-semibold text-gray-600 hover:text-gray-500"
                  >
                    {i18n.t('individual_pages.sign_in.forget_password')}
                  </a>
                </div>
              </div>

              <div>
                <button className={`${styles.button} w-full`} type="submit">
                  {i18n.t('action.sign_in')}
                </button>
              </div>
            </form>

            <div className="mt-10 text-center text-sm text-gray-500">
              {i18n.t('individual_pages.sign_in.not_a_member')} <br />
              <a href="/signup" className="font-semibold leading-6">
                {i18n.t('action.sign_up')}
              </a>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
