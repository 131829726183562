import {Outlet} from 'react-router-dom';
import React, {Suspense} from 'react';
import Footer from './Footer';
import LanguageChangeSelectBox from "./LanguageChangeSelectBox";

export default function NoAuthLayout() {
  return (
    <>
      <div className="flex flex-col min-h-screen">
        <div className="border-b-2">
          <div className="flex lg:flex-1 p-2">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">SIGQ</span>
              <img className="h-8 w-auto" src="/images/sigq-logo-text.png"
                   alt=""/>
            </a>
          </div>
        </div>
        <div className="flex-column flex-grow" style={{width: "100%", overflow: "scroll"}}>
          <div>
            <Suspense fallback={<div>Loading...</div>}>
              <Outlet/>
            </Suspense>
          </div>
        </div>
        <div className="flex flex-row-reverse mb-1 mr-1">
          <div className="w-32 text-right">
            <LanguageChangeSelectBox/>
          </div>
        </div>
        <Footer/>
      </div>
    </>
  );
}
