import I18n from '../i18n';

export default function Footer() {
  return (
    <footer className="bg-white border-y-2 text-xs">
      <div className="max-w-screen-xl py-1 mx-auto md:py-3 md:px-6 p-2">
        <div>
          <div className="flex flex-wrap md:w-4/5 m-auto">
            <div className="w-1/2 lg:w-1/4 mb-4">
              <a href="/company" className="hover:underline">
                {I18n.t('noun.corporate_site')}
              </a>
            </div>
            <div className="w-1/2 lg:w-1/4 mb-4">
              <a href="/privacy-policy" className="hover:underline">
                {I18n.t('noun.privacy_policy')}
              </a>
            </div>
            <div className="w-1/2 lg:w-1/4 mb-4">
              <a
                href="https://storage.googleapis.com/sigq-public-docs/%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84.pdf"
                className="hover:underline"
              >
                {I18n.t('noun.terms')}
              </a>
            </div>
            <div className="w-1/2 lg:w-1/4 mb-4">
              <a
                href="/specified-commercial-transactions"
                className="hover:underline"
              >
                {I18n.t('noun.specified_commercial_transactions')}
              </a>
            </div>
          </div>
          <p className="block text-sm text-center text-gray-500 mt-3">
            © 2023 SIGQ. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
