import moment from 'moment-timezone';
import I18n from "../i18n";
import 'moment/locale/ja';

export const parseUnixtimeToStr = (time: string): string => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return moment.unix(Number(time)).tz(timeZone).locale(I18n.language).format('LLL').toString();
};

export const parseDate = (time: string): string => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return moment(time).tz(timeZone).locale(I18n.language).format('LLL').toString();
};

export const parseDateToUnix = (time: string): number => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return moment(time).tz(timeZone).unix()
};