export const level1ApiEndpoint = (): string => {
  return `${process.env.REACT_APP_BACKEND_URL}/level1`
}

export const level2ApiEndpoint = (): string => {
  return `${process.env.REACT_APP_BACKEND_URL}/level2`
}

export const level3ApiEndpoint = (): string => {
  return `${process.env.REACT_APP_BACKEND_URL}/level3`
}
