import {useEffect, useState} from "react";
import {APIResponse, OperationLog, Organization} from '../types'
import {getUAStr} from "../utils/user_agent";
import {parseDate} from "../utils/date";
import i18n from "../i18n";
import {level3ApiEndpoint} from "../utils/apiEndpointUrl";
import {User} from "firebase/auth";

export default function OperationLogList(
  {
    currentUser,
    currentOrganization,
  }: {
    currentUser: User;
    currentOrganization: Organization;
  }) {
  const [operationLogs, setOperationLogs] = useState<OperationLog[]>([])
  useEffect(() => {
    currentUser
      .getIdToken(true)
      .then((idToken) => {
        const formData = new FormData();
        fetch(
          `${level3ApiEndpoint()}/operationLogs?organizationId=${currentOrganization.id}`,
          {
            headers: {'Authorization': idToken},
          }
        )
          .then(async (response) => await response.json())
          .then((data: APIResponse<OperationLog[]>) => {
            setOperationLogs(data.content)
          });
      })
      .catch(function (error) {
        console.log(error)
      });
  }, [])

  return (
    <div className="p-4 sm:p-6 lg:p-8 content-center w-full">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">{i18n.t('noun.operation_log')}</h1>
          </div>
          {/* <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Export
          </button>
        </div> */}
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                <tr>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {i18n.t('noun.operator')}
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    IP
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {i18n.t('noun.user_agent')}
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Operation Name
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Resource Name
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Resource ID
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    MTTP Method
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    status
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    created at
                  </th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {operationLogs.map((operationLog) => (
                  <tr key={operationLog.id}>
                    <td
                      className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{operationLog.organizationMemberEmail}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{operationLog.ip}</td>
                    <td
                      className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{getUAStr(operationLog.userAgent)}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{operationLog.operationName}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{operationLog.resourceName}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{operationLog.resourceId}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{operationLog.httpMethod}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{operationLog.status}</td>
                    <td
                      className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{parseDate(operationLog.createdAt)}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
