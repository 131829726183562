export default function SpecifiedCommercialTransactions() {
  return (
    <div className="p-6 max-w-2xl mx-auto bg-white">
      <h3 className="mb-4">特定商取引法に基づく表示</h3>

      <div className="mb-6">
        <h4 className="">1. 事業者の名称</h4>
        <p>合同会社SIGQ</p>
      </div>

      <div className="mb-6">
        <h2>2. 代表者または通信販売に関する業務の責任者の⽒名</h2>
        <p>⾦築 敬晃</p>
      </div>

      <div className="mb-6">
        <h2>3. 住所</h2>
        <p>〒1500043 東京都渋⾕区道⽞坂1丁⽬10番8号渋⾕道⽞坂東急ビル2F−C</p>
      </div>

      <div className="mb-6">
        <h2>4. 電話番号</h2>
        <p>現在メールのみでお問い合わせを受け付けております</p>
      </div>

      <div className="mb-6">
        <h2>5. メールアドレス</h2>
        <p>support@sigq.io</p>
      </div>

      <div className="mb-6">
        <h2>6. 商品の販売価格・サービスの対価</h2>
        <p>各商品・サービスのご購⼊ページにて表⽰する価格</p>
      </div>

      <div className="mb-6">
        <h2>7. 対価以外に必要となる費⽤</h2>
        <p>各商品またはサービスの購⼊ページにおいて条件を表⽰します。</p>
        <p>
          ※なお、インターネット接続料⾦その他の電気通信回線の通信に関する費⽤はお客様にて別途ご⽤意いただく必要があります。
        </p>
      </div>

      <div className="mb-6">
        <h2>8. ⽀払⽅法</h2>
        <p>クレジット決済</p>
      </div>

      <div className="mb-6">
        <h2>9. 代⾦の⽀払時期</h2>
        <p>
          【クレジットカード】ご利⽤のクレジットカードの締め⽇や契約内容により異なります。ご利⽤されるカード会社までお問い合わせください。
        </p>
      </div>

      <div className="mb-6">
        <h2>10. サービス提供の時期</h2>
        <p>
          【クレジットカード】別途定めがない限り、クレジットカード利⽤の承認が下りた後、すぐにサービスをご利⽤いただけます。
        </p>
      </div>

      <div className="mb-6">
        <h2>12. 返品・キャンセルに関する特約</h2>
        <p>
          【本サイトで販売する商品・サービス】
          <br />
          購⼊⼿続完了後の返品またはキャンセルをお受けいたしません。なお、商品・サービスに瑕疵がある場合は、利⽤規約の定めに従って対応します。
        </p>
        <p>
          【サービスの場合】
          <br />
          商品の特性上、返品に応じることができず、購⼊確定後のキャンセル・返⾦についてはお受けできません。
        </p>
        <p>各商品またはサービスの購⼊ページにおいて条件を表⽰します。</p>
        <p>
          【特別な販売条件または提供条件がある商品またはサービス】
          <br />
          各商品またはサービスの購⼊ページにおいて条件を表⽰します。
        </p>
      </div>
    </div>
  );
}
