import { CallToAction } from '../components/lp/CallToAction';
import { Faqs } from '../components/lp/Faqs';
import { Hero } from '../components/lp/Hero';
import { Pricing } from '../components/lp/Pricing';
import { PrimaryFeatures } from '../components/lp/PrimaryFeatures';
import { SecondaryFeatures } from '../components/lp/SecondaryFeatures';
import { Testimonials } from '../components/lp/Testimonials';

export default function LP() {
  return (
    <>
      <Hero />
      <PrimaryFeatures />
      {/* <SecondaryFeatures /> */}
      {/* <CallToAction /> */}
      {/* <Testimonials /> */}
      <Pricing />
      {/* <Faqs /> */}
    </>
  );
}
