import {User} from "firebase/auth";
import {APIResponse, OrganizationMember} from "../types";
import I18n from "../i18n";

async function request<T>(
  url: string,
  method: 'GET' | 'POST' | 'DELETE' | 'PATCH',
  formData?: FormData,
  user?: User): Promise<[T | undefined, string | null]> {
  const option: RequestInit = {
    method: method,
    credentials: 'include',
  }
  if (user && typeof user.getIdToken === "function") {
    await user.getIdToken(true).then((idToken) => {
      Object.assign(option, {headers: {'Authorization': idToken}})
    }).catch(err => {
      console.log(err)
      return
    })
  }
  if (formData) {
    Object.assign(option, {body: formData})
  }

  const response = await fetch(url, option);
  const responseJson: APIResponse<T> = await response.json()
  if (response.ok) {
    return [responseJson.content, null];
  }
  if (responseJson.error) {
    return [undefined, I18n.t(`error.${responseJson.error}`)];
  } else {
    return [undefined, I18n.t('error.contact_support')];
  }
}

async function requestAndGetFile(
  url: string,
  method: 'GET' | 'POST' | 'DELETE' | 'PATCH',
  formData?: FormData,
  user?: User): Promise<[Response | undefined, string | null]> {
  const option: RequestInit = {
    method: method,
    credentials: 'include',
  }
  if (user && typeof user.getIdToken === "function") {
    await user.getIdToken(true).then((idToken) => {
      Object.assign(option, {headers: {'Authorization': idToken}})
    }).catch(err => {
      console.log(err)
      return
    })
  }
  if (formData) {
    Object.assign(option, {body: formData})
  }

  const response = await fetch(url, option);
  if (response.ok) {
    return [response, null];
  } else {
    return [undefined, I18n.t(`error.${response.status}`)];
  }
}

export async function requestToBackend<T>(
  user: User,
  url: string,
  method: 'GET' | 'POST' | 'DELETE' | 'PATCH',
  formData?: FormData): Promise<[T | undefined, string | null]> {
  return request(url, method, formData, user)
}

export async function requestToBackendWithoutAuth<T>(
  url: string,
  method: 'GET' | 'POST' | 'DELETE' | 'PATCH',
  formData?: FormData): Promise<[T | undefined, string | null]> {
  return request(url, method, formData)
}

export async function requestToBackendWithoutAuthAndGetFile<T>(
  url: string,
  method: 'GET' | 'POST' | 'DELETE' | 'PATCH',
  formData?: FormData): Promise<[Response | undefined, string | null]> {
  return requestAndGetFile(url, method, formData)
}
