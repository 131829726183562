import {I18nLocales} from "./types";

export const ja: I18nLocales = {
  action: {
    copy: 'コピー',
    edit: '編集',
    cancel: 'キャンセル',
    cancel_edit: '編集をキャンセル',
    change: '変更',
    save_change: '変更を保存',
    sign_out: 'サインアウト',
    sign_in: 'サインイン',
    sign_up: 'サインアップ',
    delete: '削除',
    create: '作成',
    upload: 'アップロード',
    download: 'ダウンロード',
    send: '送信',
    save: '保存',
    add: '追加',
    share: '共有',
    select: '選択',
    preview: 'プレビュー',
  },
  noun: {
    email: 'メールアドレス',
    password: 'パスワード (大文字と小文字を含む最低8文字以上)',
    password_confirmation: 'パスワード確認',
    organization: '組織',
    admin_organization_setting: '[管理者] 組織設定',
    organization_setting: '組織設定',
    organization_member_setting: 'メンバー設定',
    access_count: 'アクセス数',
    resource: 'リソース',
    memo: 'メモ',
    purpose_for_share: '共有の目的',
    expiration: '有効期限',
    updated_at: '更新日',
    created_at: '作成日',
    accessed_at: 'アクセス日',
    last_modified_by: '最終更新者',
    access_log: 'アクセスログ',
    timezone: 'タイムゾーン',
    ip: 'IPアドレス',
    location: '場所',
    user_agent: 'ユーザーエージェント',
    language: '言語',
    admin: '管理者',
    role: '役割',
    user_setting: 'ユーザー設定',
    uploaded_resources: 'アップロードされたファイル',
    resource_management: 'ファイル・共有管理',
    sharing_resources: '共有中のファイル',
    access_log_explorer: 'アクセスログ分析',
    accessible_emails: 'アクセス可能なメール',
    operation_log: '操作ログ',
    environment_setting: '環境設定',
    admin_organization_member_setting: '組織メンバー管理',
    plan: 'プラン',
    team: 'チーム',
    current_plan: '現在のプラン',
    operator: '運営者',
    about_company: '会社概要',
    privacy_policy: 'プライバシーポリシー',
    terms: '利用規約',
    specified_commercial_transactions: '特定商取引法に基づく表示',
    corporate_site: '運営企業'
  },
  explanation: {
    supported_file_format: '対応ファイル形式: .pdf',
    user_setting: 'ユーザー設定は、同じユーザー情報で複数の組織に所属している際に、複数の組織間で使用される情報です。',
    organization_member: '現在表示されている組織内で使用されるメンバーの設定です。',
    organization_setting: '現在表示されている組織の設定です。',
    team_plan: 'すべての機能が利用可能',
  },
  error: {
    invalid_email: '無効なメールフォーマットです',
    invalid_password: '無効なパスワードフォーマットです',
    contact_support: '予期せぬエラーが発生しました。問題が続く場合は、カスタマーサポートに連絡してください。',
    invalid_file: 'このファイルはアップロードできません',
    password_does_not_match: 'パスワードが一致しません',
    internal_server_error: '時間をおいてもう一度お試しいただくか、サポートに連絡してください',
    invalid_request: '送信された内容にエラーがあります',
    user_not_found: 'ユーザーが見つかりません',
    wrong_password: 'パスワードが違います',
    too_many_requests: 'リクエストの制限を超えました。後ほど再度お試しください。'
  },
  confirmation: {
    are_you_sure_to_delete: '削除してもよろしいですか？',
    are_you_sure_to_sign_out: 'サインアウトしてもよろしいですか？',
  },
  notification: {
    success_delete: '削除しました',
    copied_to_clipboard: 'クリップボードにコピーしました'
  },
  state: {
    is_sharing: '共有中',
  },
  individual_pages: {
    receive: {
      new_resource_arrived: '書類が届きました',
      messageBody: "こちらのリンクから {{expireAt}} までアクセス可能です",
      verify_email: '共有者から指定されたメールアドレスを入力してください',
      verify_access_code: 'アクセスコードを入力してください',
    },
    sign_in: {
      forget_password: 'パスワードを忘れましたか?',
      not_a_member: 'アカウントをお持ちではありませんか?',
      reset_password_email_sent: 'パスワードをリセットするメールを送信しました。メールボックスをご確認ください。'
    },
    sign_up: {
      signup_with_confirmation: '規約に同意してサインアップする',
      agree_to_signup1: '「規約に同意してサインアップする」をクリックすることで、規約に同意したものとみなします。',
      agree_to_signup2: '・利用規約およびプライバシーポリシーを読み、同意する',
      agree_to_signup3: '・必要に応じて、メールで広告、プロモーション、その他の通知を送信することがあります',
      successfully_created_user: 'ユーザー登録が正常に完了しました',
      password_place_holder: '大文字と小文字、記号を含む最低8文字'
    },
    create_organization: {
      organization_member_name: '組織内のメンバー名',
      create_user: 'ユーザー登録',
      create_office: '組織作成',
      go_to_dashboard: 'ダッシュボードへ',
      explanation1: 'この画面は組織を作成するためのものです',
      explanation2: 'ステップ1で作成されたユーザーは複数の組織に属することができ、ここで作成された組織の管理者になります。',
      explanation3: 'ここで作成された組織へのメンバーへの招待は、ダッシュボード内の組織管理から行うことができます。',
    },
  }
}
