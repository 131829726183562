export class Resource {
  public id: string;
  public organizationId: string;
  public createdBy: string;
  public name: string;
  public memo: string;
  public resourceAccessControls: {
    lastModifiedBy: string;
    shareDestinationEmails: { email: string }[];
    purpose: string;
    id: string;
    allowDownload: boolean | null;
    expireAt: string;
    isSharing: 'true' | 'false';
    createdAt: '',
    updatedAt: '',
  }[];
  public createdAt: string;
  public updatedAt: string;

  constructor() {
    this.id = '';
    this.organizationId = '';
    this.createdBy = '';
    this.name = '';
    this.memo = '';
    this.resourceAccessControls = [{
      id: '',
      lastModifiedBy: '',
      purpose: '',
      shareDestinationEmails: [{email: ''}],
      allowDownload: false,
      expireAt: '',
      isSharing: 'true',
      createdAt: '',
      updatedAt: '',
    }];
    this.createdAt = ''
    this.updatedAt = ''
  }
}

export class ResourceWithOneResourceAccessControl {
  public id: string;
  public organizationId: string;
  public createdBy: string;
  public name: string;
  public memo: string;
  public resourceAccessControl: {
    lastModifiedBy: string;
    shareDestinationEmails: { email: string }[];
    purpose: string;
    allowDownload: boolean | null;
    id: string;
    expireAt: string;
    isSharing: 'true' | 'false';
    createdAt: '',
    updatedAt: '',
  };
  public createdAt: string;
  public updatedAt: string;

  constructor() {
    this.id = '';
    this.organizationId = '';
    this.createdBy = '';
    this.name = '';
    this.memo = '';
    this.resourceAccessControl = {
      id: '',
      lastModifiedBy: '',
      purpose: '',
      allowDownload: false,
      shareDestinationEmails: [{email: ''}],
      expireAt: '',
      isSharing: 'true',
      createdAt: '',
      updatedAt: '',
    };
    this.createdAt = ''
    this.updatedAt = ''
  }
}
