import i18n from "../i18n";
import styles from "../styles/Common.module.css"

const japanese = '日本語'
export default function LanguageChangeSelectBox() {
  const changeLanguage = async (language: string) => {
    let lang = 'ja'

    await i18n.changeLanguage(lang)
    localStorage.setItem("language", lang);
    location.reload();
  }

  const languageOptions = () => {
    return japanese
  }

  return (
    <select
      id="language"
      name="language"
      className={styles.languageSelectBox}
      defaultValue={languageOptions()}
      onChange={async (event) => {
        await changeLanguage(event.target.value)
      }}
    >
      <option>{japanese}</option>
    </select>
  )
}
